var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',{attrs:{"primary-title":""}},[_vm._v(" "+_vm._s(_vm.getName())+" "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","color":"default"},on:{"click":function($event){_vm.$store.state.masterusuarios.drawer =
          !_vm.$store.state.masterusuarios.drawer}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),(_vm.$store.state.masterusuarios.tipo == 'filter')?_c('v-card-text',[_c('div',{staticClass:"col-12 py-1"},[_c('v-text-field',{attrs:{"label":"Código","clearable":_vm.$store.state.masterusuarios.tipo != 'ver'},model:{value:(_vm.$store.state.masterusuarios.filtros.valorcodigo),callback:function ($$v) {_vm.$set(_vm.$store.state.masterusuarios.filtros, "valorcodigo", $$v)},expression:"$store.state.masterusuarios.filtros.valorcodigo"}})],1),_c('div',{staticClass:"col-12 py-1"},[_c('v-text-field',{attrs:{"label":"Nombre","clearable":_vm.$store.state.masterusuarios.tipo != 'ver'},model:{value:(_vm.$store.state.masterusuarios.filtros.description),callback:function ($$v) {_vm.$set(_vm.$store.state.masterusuarios.filtros, "description", $$v)},expression:"$store.state.masterusuarios.filtros.description"}})],1),_c('div',{staticClass:"col-12 py-1"},[_c('span',[_vm._v(" Estado")]),_c('v-radio-group',{attrs:{"row":"","dense":""},model:{value:(_vm.$store.state.masterusuarios.filtros.status),callback:function ($$v) {_vm.$set(_vm.$store.state.masterusuarios.filtros, "status", $$v)},expression:"$store.state.masterusuarios.filtros.status"}},[_c('v-radio',{attrs:{"label":"Activo","color":"green","value":true}}),_c('v-radio',{attrs:{"label":"Inactivo","color":"red","value":false}}),_c('v-radio',{attrs:{"label":"Todos","color":"blue","value":"null"}})],1)],1)]):_c('v-card-text',[_c('v-form',{ref:"frmDML"},[_c('div',{staticClass:"col-12 py-1"},[_c('v-text-field',{attrs:{"readonly":_vm.$store.state.masterusuarios.tipo == 'ver',"label":"Nombre (*)","clearable":_vm.$store.state.masterusuarios.tipo != 'ver',"rules":[
            function (v) { return !!v || 'Dato Requerido'; },
            function (v) { return (!!v && v.length <= 500) ||
              'El nombre no puede tener más 500 de  carácteres'; } ]},model:{value:(_vm.$store.state.masterusuarios.model.description),callback:function ($$v) {_vm.$set(_vm.$store.state.masterusuarios.model, "description", $$v)},expression:"$store.state.masterusuarios.model.description"}})],1),_c('div',{staticClass:"col-12 py-1"},[_c('span',[_vm._v(" Estado")]),_c('v-switch',{attrs:{"readonly":_vm.$store.state.masterusuarios.tipo == 'ver',"label":("Estado: " + (!!_vm.$store.state.masterusuarios.model.status ? 'Activo' : 'Inactivo')),"color":"success"},model:{value:(_vm.$store.state.masterusuarios.model.status),callback:function ($$v) {_vm.$set(_vm.$store.state.masterusuarios.model, "status", $$v)},expression:"$store.state.masterusuarios.model.status"}})],1)])],1),_c('v-card-actions',[_c('v-spacer'),(_vm.$store.state.masterusuarios.tipo == 'filter')?_c('v-btn',{staticClass:"mx-1",attrs:{"color":"success","loading":_vm.loading},on:{"click":function($event){return _vm.Filtrar()}}},[_vm._v("Aceptar")]):_vm._e(),(_vm.$store.state.masterusuarios.tipo == 'filter')?_c('v-btn',{staticClass:"mx-1",attrs:{"color":"default","loading":_vm.loading},on:{"click":function($event){return _vm.Limpiar()}}},[_vm._v("Limpiar")]):_vm._e(),(
        _vm.$store.state.masterusuarios.tipo != 'filter' &&
        _vm.$store.state.masterusuarios.tipo != 'ver'
      )?_c('v-btn',{staticClass:"mx-1",attrs:{"color":"success","loading":_vm.loading},on:{"click":function($event){return _vm.Aceptar()}}},[_vm._v("Aceptar")]):_vm._e(),_c('v-btn',{staticClass:"mx-1",attrs:{"color":"warning","loading":_vm.loading},on:{"click":function($event){_vm.$store.state.masterusuarios.drawer =
          !_vm.$store.state.masterusuarios.drawer}}},[_vm._v("Cerrar")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }